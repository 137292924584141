
import Vue, { PropType } from 'vue'
import { Block } from '@interfaces/BlockTypes'
import CommonVideoPlayerDialog from '~/components/common/CommonVideoPlayerDialog.vue'
import AppActions from '@constants/AppActions'

export default Vue.extend({
  name: 'CommonTestimonialsBlock',
  components: { CommonVideoPlayerDialog },
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
      observer: null as any,
      autoSwitchTimer: 0 as any,
      featureSelected: 0,
      isPaused: true,
      featureStates: {},
      autoScrollActive: true,
      isDialogOpen: false,
      showThumbnail: true,
      youtubeEmbedUrl: this.block?.video?.link,
      currentVideoUrl: '',
    }
  },
  mounted() {
    this.createObserver()
  },
  computed: {
    isYoutubeLink() {
      return this.block?.video?.link?.includes('youtube')
    },
    videoPoster(): string {
      const t = this.block?.video?.thumbnail
      return typeof t === 'string' ? t : t?.url || ''
    },
  },
  methods: {
    async videoTracking() {
      if (this.block?.video?.enableTracking) {
        await this.$store.dispatch(AppActions.TRACK_WATCH_TESTIMONIAL_EVENT, {
          context: this,
          location: this.block?.video?.trackingLocation,
          link: this.block?.video?.link || null,
        })
      }
    },
    handleVideoClick() {
      this.toggleVideo()
      this.videoTracking()
    },
    createObserver() {
      const options = {
        root: null,
        threshold: 0.1,
      }
      this.observer = new IntersectionObserver(this.handleIntersect, options)
      this.observer.observe(this.$el)
    },
    userInteraction() {
      this.autoScrollActive = false
      this.clearAutoSwitchTimer()
    },
    handleIntersect(entries: IntersectionObserverEntry[]) {
      if (entries[0].isIntersecting) {
        this.setAutoSwitch()
      } else {
        this.clearAutoSwitchTimer()
      }
    },

    selectPreviousFeature() {
      this.userInteraction()
      if (this.featureSelected > 0) {
        this.featureSelected--
      } else {
        this.featureSelected = Object.keys(this.block.features).length - 1
      }
    },
    selectNextFeature() {
      const numberOfFeatures = Object.keys(this.block.features).length
      if (this.featureSelected < numberOfFeatures - 1) {
        this.featureSelected++
      } else {
        this.featureSelected = 0
      }
      if (this.autoScrollActive) {
        this.setAutoSwitch()
      }
    },
    setAutoSwitch() {
      this.clearAutoSwitchTimer()
      const feature = this.block.features[this.featureSelected]
      const duration = (feature.duration || 5) * 1000
      this.autoSwitchTimer = setTimeout(() => {
        this.selectNextFeature()
      }, duration)
    },
    clearAutoSwitchTimer() {
      clearTimeout(this.autoSwitchTimer)
      this.autoSwitchTimer = 0
    },
    toggleVideo() {
      this.currentVideoUrl = this.block?.video?.link
      this.isDialogOpen = true
    },
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
    this.clearAutoSwitchTimer()
  },
})
